<template>
    <div>
        <div class="relative pt-1">
            <div class="flex mb-2 items-center justify-between">
                <div>
                    <span class="text-xs font-semibold inline-block py-1">
                        {{ label }}
                    </span>
                </div>
                <div class="text-right">
                    <span class="text-xs font-semibold inline-block">
                        {{ value ?? '?' }}
                    </span>
                </div>
            </div>
            <div class="overflow-hidden h-3 mb-4 text-xs flex rounded bg-gray-600">
                <div :style="'width:'+value+'%'" :class="bar_color(value)" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['value', 'label'],
    methods: {
        bar_color: function(val) {
            if (val <= 33){
                return 'background-red';
            }
            if (val <= 66){
                return 'background-orange';
            }
            return 'background-green';
        }
    },
}
</script>
<style scoped>
.background-red {
  background-color: #A62130;
}

.background-orange {
  background-color: #f56d2c;
}

.background-green {
  background-color: #5CB824;
}
</style>