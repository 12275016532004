<template>
    <div class="flex items-center mb-4">
        <div class="p-3 border border-white rounded w-6 h-6 inline-block mr-5" :class="value ? 'bg-gradient-to-r from-scorelitorange to-scorelitred' : ''"></div>
        <div class="inline-block font-normal text-sm">{{ label }}</div>
    </div>
</template>

<script>

export default {
    props: ['value', 'label'],
}
</script>