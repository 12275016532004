<template>
  <div>
    <TinyNavigation
      :prev="{ name: 'review.show', params: { id: this.id } }"
      class="pt-6 px-6"
    />

    <template v-if="!review_not_found">
      <div class="px-3 pt-10">
        <h1 class="heading-1 pb-4" v-text="category_decoded"></h1>
      </div>

      <!-- switcher: Show only if highlights are present -->
      <div
        v-if="highlights && highlights.length !== 0"
        class="flex flex-wrap mt-2 mb-10 rounded border border-gray-400 overflow-hidden"
      >
        <div class="w-1/2">
          <button
            type="option-active"
            :class="!show_highlights ? 'option-active w-full' : 'option w-full'"
            v-on:click.prevent="show_highlights = false"
          >
            {{ t('switcher.reviews') }}
          </button>
        </div>
        <div class="w-1/2">
          <button
            type="option-active"
            :class="show_highlights ? 'option-active w-full' : 'option w-full'"
            v-on:click.prevent="show_highlights = true"
          >
            {{ t('switcher.highlights') }}
          </button>
        </div>
      </div>

      <!-- Highlights -->
      <template v-if="show_highlights">
        <Highlight
          v-for="highlight in highlights"
          :key="highlight.id"
          :highlight="highlight"
          :video="review.match.video"
        />
      </template>

      <!-- Review items -->
      <template v-else>
        <div
          v-for="(answer, index) in answers"
          :key="index"
          class="border-t border-b border-gray-600 py-8 px-4"
        >
          <h4 class="text-white font-bold pb-4">{{ answer.description }}</h4>
          <template v-if="answer.type === 'checkbox'">
            <Check
              v-for="(option, index) in answer.options"
              :key="index"
              :value="option.checked"
              :label="option.title"
              class="text-white"
            />
          </template>
          <template v-else-if="answer.type === 'score'">
            <Bar
              v-for="(option, index) in answer.options"
              :key="index"
              :value="option.value"
              :label="option.title"
              class="text-white"
            />
          </template>
          <div v-if="answer.conclusion" class="text-white pt-4">
            <p class="font-semibold text-sm">Conclusie</p>
            <p class="my-4 p-4 border rounded-md bg-gray-900 text-xs">
              {{ answer.conclusion }}
            </p>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <p class="text-white px-12 pt-10">{{ t('review_not_found') }}</p>
    </template>
  </div>
</template>

<script>
import Highlight from "@/components/review/HighlightItem";
import Check from "@/components/statistics/Check";
import Bar from "@/components/statistics/Bar";
import TinyNavigation from "@/components/TinyNavigation";
import { getReview } from "@/helpers/api/ReviewHelper";
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
  name: "ReviewShowCategory",
    setup() {
        return useI18n({
            viewPrefix: "ReviewShowCategory"
        });
    },
  components: { TinyNavigation, Highlight, Check, Bar },
  props: ["id", "category"],
  created() {
    getReview(this.id)
      .then((review) => {
        this.review = review;
      })
      .catch((err) => {
        if (err.status === 404 || err.status === 403) {
          this.review_not_found = true;
        } else {
          messages.error(this.t('messages.something_went_wrong', {error: err.message}));
        }
      });
  },
  data() {
    return {
      review_not_found: false,
      review: {},
      show_highlights: false
    };
  },
  computed: {
    highlights: function() {
      if (!this.review || !this.review.timestamps) {
        return [];
      }
      return this.review.timestamps.filter(
        (ts) => ts.title.toLowerCase() == this.category_decoded.toLowerCase()
      );
    },
    category_decoded: function() {
      return decodeURI(this.category);
    },
    answers: function() {
      if (!this.review || !this.review.questions) {
        return undefined;
      }
      const group = this.review.questions.find(
        (group) => group.title == this.category_decoded
      );
      if (!group) {
        return undefined;
      }
      // console.log('group:', group.questions)
      return group.questions;
    }
  }
};
</script>
